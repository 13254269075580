import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import { Container } from "react-bootstrap"
import Dropdown from "../main/Dropdown"

import Image1 from "../../assets/images/case-study1/case-study1.png"
import Image2 from "../../assets/images/case-study1/paul-head-shot.jpeg"
import { AsSeenIn } from "../main/AsSeenIn"
import Filmmakers from "../main/Filmmakers"
import DisclaimerBar1 from "../main/DisclaimerBar1"
import favicon from "../../assets/images/nlc-favicon.png"
import CaseStudy1Modal2 from "./CaseStudy1Modal2"

export default function CaseStudy1() {
  const [isOpen, setIsOpen] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen)
  }

  // Freeze the body background if the modal is open
  // useEffect(() => {
  //   if (modalIsOpen) {
  //     document.body.style.overflow = "hidden"
  //   } else {
  //     document.body.style.overflow = "unset"
  //   }
  // }, [modalIsOpen])

  return (
    <>
      <Helmet
        title="Nexl Level Creators | The Best Online Courses For Learning Content Creation"
        meta={[
          {
            name: "description",
            content:
              "The Best Online Courses For Learning Content Creation. Commit to daily growth by enrolling in immersive, online classes with the worlds best instructors. Unlock your creative potential.",
          },
          { name: "keywords", content: "...." },
        ]}
        link={[{ rel: "shortcut icon", type: "image/png", href: `${favicon}` }]}
      />
      {/* <Modal2 show={true} onHide={() => setModalShow(false)} /> */}
      <Dropdown isOpen={isOpen} toggle={toggle} />
      {/* <NavigationBar toggle={toggle} /> */}
      <CaseStudy1Modal2 modalIsOpen={modalIsOpen} toggleModal={toggleModal} />
      <section className="themes__panel-light">
        <Container className="themes__panel-light themes__flex-center-all">
          <ContentContainer1>
            <BasicH1>
              <b>Free Case Study:</b>
              <br /> "How Content Creators Are Landing $24k+ Video Production
              Clients In 4 Simple Steps"
            </BasicH1>
            <ButtonContainer>
              <button
                className="btn btn-primary btn-large"
                onClick={toggleModal}
              >
                Yes, I Want The Free Case Study!
              </button>
            </ButtonContainer>
            <BasicImage1 src={Image1} alt="Paul and Anthony behind a graph." />
            <BasicH1>What You Will Learn:</BasicH1>
            <div className="case-study1__justify-left">
              <BasicP>
                1. <b>Value Creation:</b> How to leverage{" "}
                <i>video production + simple online ads</i> to deliver{" "}
                <u>measurable growth as value</u> to your clients.
              </BasicP>
              <BasicP>
                2. <b>Leverage:</b> How to bring in a{" "}
                <i>flood of new customers</i> for your client even if you are{" "}
                <u>brand new to commercial filmmaking & paid ads</u>.
              </BasicP>
              <BasicP>
                3. <b>Getting Clients:</b> How to find business owners who are{" "}
                <i>more than willing to pay you</i>, and land your very first{" "}
                <u>video campaign client</u> right away, even if you have no
                paid ads experience.
              </BasicP>
              <BasicP>
                4. <b>Differentiator:</b> How to develop a{" "}
                <i>competitive advantage</i> as a commercial filmmaker without{" "}
                <u>spending years building a highlight reel</u>.
              </BasicP>
              <BasicP>
                5. <b>Focus:</b> The secret to working with your IDEAL CLIENTS
                so you can shoot the videos you are <i>passionate</i> about.
              </BasicP>
            </div>
          </ContentContainer1>
        </Container>
      </section>
      <section className="as-seen-in__section">
        <Container>
          <AsSeenIn intro="Feautured In:" />
        </Container>
      </section>
      <section className="themes__panel-dark">
        <Container className="case-study1__filmmaker-container themes__flex-center-all">
          <Filmmakers
            intro="Content Creators Using Just Like You Using These 4 Systems To Grow"
            theme="themes__panel-dark"
          />
        </Container>
      </section>

      <section className="themes__panel-light">
        <Container className="themes__panel-light themes__flex-center-all">
          <ContentContainer2>
            <BasicH1>Meet Your Mentor</BasicH1>
            <BasicImage2 src={Image2} alt="Paul Xavier head shot." />
            <BasicH1>Paul Xavier</BasicH1>
            <div className="case-study1__justify-left">
              <BasicP>
                Paul has helped over 1,000 content creators scale their video
                agencies, increase their rates, and deliver tangible results to
                their clients through video marketing.
              </BasicP>
              <BasicP>
                Forbes, Inc, Entrepreneur, and the Go Creative Show have
                featured Pul for his work helping other creators to make a
                living by mastering simple business systems.
              </BasicP>
              <BasicP>
                In the last 8 years, Paul has invented multiple systems that are
                helping filmmakers all over the globe to grow including:
              </BasicP>
              <BasicP>
                1. <b>Earn The Deal:</b> The <u>most effective sales model</u>{" "}
                on the planet for content creators.
              </BasicP>
              <BasicP>
                2. <b>Mega-Value Marketing:</b> How to{" "}
                <i>predictively & consistently</i> generate leads, appointments
                & <u>sales opportunities</u> for your video agency.
              </BasicP>
              <BasicP>
                3. <b>1 Day Script:</b> A streamlined process on how to{" "}
                <i>write, shoot, & edit</i> videos that sell on <u>day 1</u> so
                you can generate clients a <i>return on investment</i>.
              </BasicP>
              <BasicP>
                4. <b>Creators Operating System:</b> A <i>comprehensive</i>{" "}
                business model and sequence for <i>profitably</i> scaling a
                video production agency at <u>world record speed</u>.
              </BasicP>
            </div>
          </ContentContainer2>
          <ButtonContainer>
            <button className="btn btn-primary btn-large" onClick={toggleModal}>
              Yes, I Want The Free Case Study Right Now!
            </button>
          </ButtonContainer>
          <Spacer1 />
        </Container>
      </section>
      <DisclaimerBar1 />
    </>
  )
}

const ContentContainer1 = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  text-align: left;
  width: 80%;
  padding-bottom: 20px;

  @media screen and (max-width: 678px) {
    width: 100%;
  }
`

const ContentContainer2 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  text-align: left;
  width: 80%;
  padding-bottom: 20px;
`

const BasicH1 = styled.h1`
  display: block;
  font-family: "Open Sans", "Poppins", Arial, Helvetica, sans-serif;
  color: rgb(89, 89, 89);
  font-size: clamp(2rem, 3vw, 3rem);
  text-align: center;
  font-weight: 500;
  color: black;
  padding: 20px;
  margin: 0px;

  @media screen and (max-width: 678px) {
    font-size: 1.4rem;
  }
`
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 20px;
`

const BasicImage1 = styled.img`
  max-width: 100%;
  box-shadow: 8px 7px 10px #888890;
  margin: 20px;
`

const BasicImage2 = styled.img`
  display: block;
  max-width: 80%;
  box-shadow: 0px 0px 17px 8px #888890;
`
const BasicP = styled.p`
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-size: 1.2rem;
  margin-top: 10px;
  margin-bottom: 10px;
`
const Spacer1 = styled.div`
  height: 150px;
  background: white;
`
